exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-consumables-js": () => import("./../../../src/pages/consumables.js" /* webpackChunkName: "component---src-pages-consumables-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-equipments-js": () => import("./../../../src/pages/equipments.js" /* webpackChunkName: "component---src-pages-equipments-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manufacturers-js": () => import("./../../../src/pages/manufacturers.js" /* webpackChunkName: "component---src-pages-manufacturers-js" */),
  "component---src-templates-item-template-js": () => import("./../../../src/templates/itemTemplate.js" /* webpackChunkName: "component---src-templates-item-template-js" */),
  "component---src-templates-items-template-js": () => import("./../../../src/templates/itemsTemplate.js" /* webpackChunkName: "component---src-templates-items-template-js" */)
}

