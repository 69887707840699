import { createGlobalStyle } from 'styled-components';
import bg from '../assets/images/bg.svg';
import 'normalize.css';

const GlobalStyles = createGlobalStyle`

  /* Ensuring elements are sized predictably */
  *, *::before, *::after {
    box-sizing: border-box;
  }
  
  /*Set base font properties that apply to the whole website*/
  html {
    font-size: 16px; /* or 62.5% for easier rem calculations */
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.4;
  }

  /* Remove default margin and padding*/
  body {
    background: #fff;
  }
  html, body{
    height: 100%; /* Ensure full height */
    margin: 0;
    padding: 0;
    //overflow-x: hidden; // Prevent horizontal scroll
  }

  a {
    text-decoration: none;
    color: #0645ad; /* default link color */
  }

  /* Set core body defaults */
  h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }

  input, button, textarea, select {
    font: inherit; /* To ensure the font consistency */
  }

  @media (max-width: 768px) {
    /* Styles for tablets and below */
    font-size: 14px;
  }

  @media (max-width: 480px) {
    /* Styles for phones and below */
    font-size: 13px;
  }

  //Image background
  /*html {
    background-image: url(${bg});
    background-size: 450px;
    background-attachment: fixed;
  }*/

  /*body {
    font-size: 2rem;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }*/

  /* Scrollbar Styles */
  /*body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: #FF4949 #fff;
  }
  body::-webkit-scrollbar-track {
    background: #fff;
  }
  body::-webkit-scrollbar-thumb {
    background-color: #FF4949 ;
    border-radius: 6px;
    border: 3px solid #fff;
  }
*/

`;

export default GlobalStyles;