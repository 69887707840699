import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import logo from "../assets/images/logo.svg";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  animation: ${props => (props.$fadeeffect ? fadeIn : fadeOut)} 0.75s;
  animation-fill-mode: forwards;
`;

const LogoImage = styled.img`
  width: 40%;
  height: auto;
`;

const LogoAnimation = () => {
  const [fadeeffect, setfadeeffect] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setfadeeffect(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <LogoContainer $fadeeffect={fadeeffect}>
      <LogoImage src={logo} alt="Soquelec" />
    </LogoContainer>
  );
};

export default LogoAnimation;
