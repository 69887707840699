import React, {useContext} from 'react'
import styled from "styled-components";
import { LanguageContext } from '../contexts/LanguageContext';
import Button from '@mui/material/Button';
import TranslateIcon from '@mui/icons-material/Translate';

const StyledFooter = styled.footer`
    background-color: #fff;
    color: #5C6979;
    padding: 20px;
    text-align: center;
    
    .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
    

  @media (max-width: 600px) {
    .footer-content {
      flex-direction: column;
    }
  }
`;

const StyledButton = styled(Button)`
    && {
        background-color: white; // Set the background color as per your mockup
        color: #5C6979; // Set the text color to white
        padding:5px 10px; // Adjust padding to your preference
        border-radius: 7px; // Set the border-radius as per your mockup
        text-transform: none; // Prevents uppercase transformation if you don't want it
        font-size: 1rem; // Adjust the font size as needed

        &:hover {
            background-color: #C4C4C4; // Darken the button on hover, adjust color as needed
        }
    }
`;

export default function Footer() {
    const { language, setLanguage } = useContext(LanguageContext);

  const toggleLanguage = () => {
    setLanguage(lang => (lang === 'EN' ? 'FR' : 'EN'));
  };

  return (
      <StyledFooter>
          <div className="footer-content">
              <p>&copy;{new Date().getFullYear()} Soquelec {language === 'EN' ? 'LTD. All rights reserved.' : 'LTÉE. Tous droits réservés.'}</p>
              <StyledButton
              onClick={toggleLanguage}
              startIcon={<TranslateIcon />}>
              {language === 'EN' ? 'Français' : 'English'}
            </StyledButton>
          </div>
      </StyledFooter>
  );
}