import Nav from "./Nav";
import Footer from "./Footer";
import React, { useEffect, useState } from 'react';
import GlobalStyles from "../styles/GlobalStyles";
import LogoAnimation from "./LogoAnimation";
import styled, { keyframes } from 'styled-components';
import { LanguageProvider } from '../contexts/LanguageContext';
import { CartProvider } from '../contexts/CartContext';

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const ContentContainer = styled.main`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    animation: ${fadeIn} 1s;
    opacity: 0; // Start with content invisible
    animation-fill-mode: forwards;
`;

export default function Layout({ children }) {
    let isAnimationPlayed = false;
    if (typeof window !== 'undefined') {
        isAnimationPlayed = sessionStorage.getItem('animationPlayed') === 'true';
    }
    const [showAnimation, setShowAnimation] = useState(!isAnimationPlayed);

    useEffect(() => {
        if (showAnimation && typeof window !== 'undefined') {
            sessionStorage.setItem('animationPlayed', 'true');

            const timer = setTimeout(() => {
                setShowAnimation(false);
            }, 2500); // Duration of the animation

            return () => clearTimeout(timer);
        }
    }, [showAnimation]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <GlobalStyles />
            {showAnimation ? (
                <LogoAnimation />
            ) : (
                <>
                    <CartProvider>
                        <LanguageProvider>
                            <Nav />
                            <ContentContainer style={{ minHeight: 'calc(100vh - [NavHeight] - [FooterHeight])' }}>{children}</ContentContainer> {/* This allows the content to expand */}
                            <Footer />
                        </LanguageProvider>
                    </CartProvider>
                </>
            )}
        </div>
    );
}
