import React, { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState(() => {
        // Ensure localStorage is accessed only in a client-side environment
        if (typeof window !== 'undefined') {
            const localData = localStorage.getItem('cart');
            return localData ? JSON.parse(localData) : [];
        }
        return []; // Return empty array if not client-side
    });

    useEffect(() => {
        // Ensure localStorage is accessed only in a client-side environment
        if (typeof window !== 'undefined') {
            localStorage.setItem('cart', JSON.stringify(cartItems));
        }
    }, [cartItems]);

    const addToCartWithQuantity = (item, quantity) => {
        if (quantity <= 0) {
            return;
        }

        setCartItems(currentItems => {
            const existingItemIndex = currentItems.findIndex(i => i.code === item.code);
            if (existingItemIndex > -1) {
                // If item exists, update its count by adding the quantity
                const updatedItems = [...currentItems];
                updatedItems[existingItemIndex].count += quantity;
                return updatedItems;
            } else {
                // If item does not exist, add it with the specified quantity
                return [...currentItems, { ...item, count: quantity }];
            }
        });
    };

    const removeFromCart = (itemCode) => {
        setCartItems(currentItems => currentItems.filter(item => item.code !== itemCode));
    };

    const updateItemCount = (itemCode, newCount) => {
        setCartItems(currentItems => {
            return currentItems.map(item => {
                if (item.code === itemCode) {
                    // Ensure the count cannot be less than 1
                    return { ...item, count: Math.max(1, newCount) };
                }
                return item;
            });
        });
    };

    const clearCart = () => {
        setCartItems([]);
    };

    const itemsInCartCount = cartItems.reduce((total, item) => total + item.count, 0);

    return (
        <CartContext.Provider value={{ cartItems, addToCartWithQuantity, removeFromCart, updateItemCount, clearCart, itemsInCartCount }}>
            {children}
        </CartContext.Provider>
    );
};
