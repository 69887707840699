import React, { createContext, useState, useEffect } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    // Ensure localStorage access is client-side only
    if (typeof window !== 'undefined') {
      const savedLanguage = localStorage.getItem('language');
      return savedLanguage || 'EN'; // Default to 'EN' if no saved preference
    }
    return 'EN'; // Default to 'EN' if not client-side
  });

  useEffect(() => {
    // Ensure localStorage access is client-side only
    if (typeof window !== 'undefined') {
      localStorage.setItem('language', language); // Save language preference
    }
  }, [language]);

  useEffect(() => {
    // Ensure this effect runs client-side only
    if (typeof window !== 'undefined') {
      // Perform browser language detection only if there's no saved language preference
      if (!localStorage.getItem('language')) {
        const browserLanguage = navigator.language || navigator.userLanguage;
        if (browserLanguage.startsWith('fr')) {
          setLanguage('FR');
        }
      }
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
