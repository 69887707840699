import React, {useContext, useState} from 'react'
import {Link} from "gatsby";
import styled from "styled-components";
import {AppBar, Toolbar, Drawer, List, ListItemButton, useMediaQuery, useTheme, IconButton, Badge} from '@mui/material';
import logo from "../assets/images/logo.svg"
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { LanguageContext } from "../contexts/LanguageContext";
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import { useCart } from '../contexts/CartContext';


const StyledAppBar = styled(({...props}) => <AppBar {...props} />)`
    && {
        background: #fff !important; // Ensures that the background color is white and overrides any existing styles
        box-shadow: none !important; // Ensures that the shadow is removed
    }
`;

// Increasing specificity for Toolbar to ensure styles are applied
const StyledToolbar = styled(({...props}) => <Toolbar {...props} />)`
    && {
        display: flex;
        justify-content: space-between;
        min-height: 110px !important; // Set the desired height with !important to override any existing styles
        padding: 0 80px; // Adjust padding as needed


        @media (max-width: 450px) {
            padding: 0 30px; // Adjust padding as needed
            min-height: 60px !important;
        }
    }
`;

const NavLink = styled(Link)`
    font-size: 1.5rem;
    color: #5C6979; // Adjust link color
    user-select: none;
    transition: transform .3s;

    &:hover {
        color: #071C3F; // Change the hover color as needed
        transform: scale(1.03);
    }
    
    &:hover:active {
        transform: scale(1);
    }

    &[aria-current='page'] {
        color: #071C3F;
        text-decoration: underline;
        text-underline-offset: 0.4rem;
        transform: scale(1.03);
    }
`;

const StyledLogo = styled.img`
    height: 50px; // Adjust the size according to your logo
    cursor: pointer;
    user-select: none;
    
    @media (max-width: 450px) {
        height: 40px;
    }
`;

const StyledIconButton = styled(IconButton)`
    && {
        color: #071C3F; // Your custom color
    }
`;

const StyledBadge = styled(Badge)`
    & .MuiBadge-badge { 
    background-color: #5C6979;
    color: #FFFFFF;
    font-size: 0.55rem;
    height: 13px;
    min-width: 15px;
  }
`;

export default function Nav() {
    const { language } = useContext(LanguageContext);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const { itemsInCartCount } = useCart();

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const drawer = (
        <List>
            <ListItemButton  component={NavLink} to="/" onClick={handleDrawerToggle}>Soquelec</ListItemButton>
            <ListItemButton  component={NavLink} to="/equipments" onClick={handleDrawerToggle}>{language === 'EN' ? 'Equipments' : 'Instrumentation'}</ListItemButton>
            <ListItemButton  component={NavLink} to="/consumables" onClick={handleDrawerToggle}>{language === 'EN' ? 'Consumables' : 'Fournitures'}</ListItemButton>
            <ListItemButton  component={NavLink} to="/manufacturers" onClick={handleDrawerToggle}>{language === 'EN' ? 'Manufacturers' : 'Manufacturiers'}</ListItemButton>
            <ListItemButton  component={NavLink} to="/about" onClick={handleDrawerToggle}>{language === 'EN' ? 'About' : 'À Propos'}</ListItemButton>
            <ListItemButton  component={NavLink} to="/contact" onClick={handleDrawerToggle}>{language === 'EN' ? 'Contact' : 'Contactez-nous'}</ListItemButton>
            <ListItemButton  component={NavLink} to="/cart" onClick={handleDrawerToggle}>{language === 'EN' ? 'My Cart' : 'Mon Panier'}</ListItemButton>
        </List>
    );

    return (
        <StyledAppBar position="static">
            <StyledToolbar>
                <NavLink to="/"><StyledLogo src={logo} alt="Soquelec Logo"/></NavLink>

                {isMobile ? (
                    <StyledIconButton edge="end" onClick={handleDrawerToggle}>
                        <MenuRoundedIcon style={{ fontSize: '2rem', color: '#071C3F' }}/>
                    </StyledIconButton>
                ) : (
                    <>
                        <NavLink to="/equipments">{language === 'EN' ? 'Equipments' : 'Instrumentation'}</NavLink>
                        <NavLink to="/consumables">{language === 'EN' ? 'Consumables' : 'Fournitures'}</NavLink>
                        <NavLink to="/manufacturers">{language === 'EN' ? 'Manufacturers' : 'Manufacturiers'}</NavLink>
                        <NavLink to="/about">{language === 'EN' ? 'About' : 'À Propos'}</NavLink>
                        <NavLink to="/contact">{language === 'EN' ? 'Contact' : 'Contactez-nous'}</NavLink>
                        <NavLink to="/cart">
                            <StyledIconButton edge="end" aria-label="cart">
                                <StyledBadge badgeContent={itemsInCartCount} >
                                    <ShoppingCartRoundedIcon style={{ fontSize: '2rem', color: '#071C3F' }}/>
                                </StyledBadge>
                            </StyledIconButton>
                        </NavLink>
                    </>
                )}
                <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                    {drawer}
                </Drawer>
            </StyledToolbar>
        </StyledAppBar>
    );
}
